<template>
  <div id="main-content-presale-prices">
    <pricesTable />
  </div>
</template>

<script>
import pricesTable from './tables/PricesTable'
export default {
    name: 'PresalePrices',
    components: {
      pricesTable
    }
}
</script>