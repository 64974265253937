<template>
  <v-container id="user-roles-table" fluid tag="section">
    <base-material-card icon="mdi-tag-multiple" :title="$t('presale prices.table.title')">
      <prices-form ref="editForm" @itemSaved="onSaved"></prices-form>
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('presale prices.delete price')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">
          Are you sure you want to delete price with id
          <strong>{{ item.id }}</strong> ?
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{ error.message }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">Price {{ item.id }} deleted</v-alert>
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:item.id="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-tag-plus</v-icon>
              {{ $t('presale prices.add price') }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import crudMixin from 'mixins/crudTable'
import PricesForm from '../forms/PricesForm'
import DeleteDialog from '@/components/crud/DeleteDialog'

export default {
  components: {
    PricesForm,
    DeleteDialog,
  },
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: '/api/presale/prices/',
      headers: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: this.$t('presale prices.table.from'), value: 'from_date' },
        { text: this.$t('presale prices.table.to'), value: 'to_date' },
        { text: this.$t('presale prices.table.price'), value: 'price' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    onSaved(item, index) {
      if (index === -1) {
        this.total++
        item.isNew = true
        this.items.push(item)
        window.setTimeout(() => {
          this.$refs.editForm.clearForm()
        }, 1500)
      } else {
        Object.assign(this.items[index], item)
        window.setTimeout(() => {
          this.$refs.editForm.closeForm()
        }, 2500)
      }
    },
  },
}
</script>
