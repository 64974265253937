<template>
  <v-dialog v-model="showFormDialog" max-width="50vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-menu
              ref="menuFrom"
              v-model="menuFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="form.from_date"
                  label="From date"
                  prepend-icon="mdi-calendar-today"
                  readonly
                  v-on="on"
                  :rules="rules.from_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.from_date"
                no-title
                scrollable
                class="ma-0"
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              ref="menuTo"
              v-model="menuTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="form.to_date"
                  label="To date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  :rules="rules.to_date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.to_date"
                no-title
                scrollable
                class="ma-0"
                @input="menuTo = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="form.price"
              prepend-icon="mdi-cash-multiple"
              label="Price"
              :rules="rules.price"
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
          >{{ $t('Cancel') }}</v-btn>
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
          >{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{
        errorMessage
        }}
      </v-alert>
      <v-alert
        v-if="savedItem"
        tile
        type="success"
        class="mb-0"
      >The price {{ savedItem.id }} was saved with success!</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from 'mixins/crudForm'
export default {
  mixins: [crudMixin],
  data() {
    return {
      apiQuery: '/api/presale/prices/',
      menuFrom: false,
      menuTo: false,
      rules: {
        from_date: [
          v => !!v || 'Required.',
          () => this.serverErrors && this.serverErrors.from_date ? this.$t('presale prices.from_date.'+ this.serverErrors.from_date[0]) : true,
        ],
        to_date: [
          v => this.editedItem === null ? !!v || 'Required.' : true,
          () => this.serverErrors && this.serverErrors.to_date ? this.$t('presale prices.to_date.'+ this.serverErrors.to_date[0]) : true,
        ],
        price: [
          v => !!v || 'Required.',
          () => this.serverErrors && this.serverErrors.price ? this.$t('presale prices.price.'+ this.serverErrors.price[0]) : true,
        ],
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t('presale prices.add price')
        : this.$t('presale prices.edit price')
    }
  },
  methods: {},
}
</script>
